.stepindicator .breadcrumbitem {
  width: 200px;
  padding: 0px 50px;
  text-align: center
}

.stepindicator .breadcrumbitem .status {
  text-align: center;
  font-size: 0.8rem;
  min-height: 40px;
}

.stepindicator .breadcrumbitem .statuscurrent {
  font-weight: bold;
  padding-bottom: 15px;
}

.stepindicator .breadcrumbitem .visitedstep {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 1rem;
  color: white;
  text-align: center;
  padding: 0px;
  left: 40%;
  position: relative;
  background-color: #0e78ed;
  z-index: 1;
}

.stepindicator .breadcrumbitem .step {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 1rem;
  color: white;
  text-align: center;
  padding: 0px;
  left: 40%;
  position: relative;
  background-color: #8eb0e8;
  z-index: 1;
}

.stepindicator .breadcrumbitem .stepvisited {
  color: rgb(255, 119, 0);
  background-color: #0e78ed;
}

.separator {
  display: inline-block;
  position: absolute;
  content: '';
  height: 4px;
  width: 100%;
  top: 17%;
  left: 50%;
  background-color: #8eb0e8;
}

.separatorvisited {
  display: inline-block;
  position: absolute;
  content: '';
  height: 4px;
  width: 95%;
  top: 17%;
  left: 50%;
  background-color: #0e78ed;
}

.InogicLogo {
  height: 40px;
}

.productImg {
  height: 40px;
}

.productName {
  font-weight: 300;
  font-weight: bold;
  font-size: 24px;
  width: 75%;
  text-align: center
}

.headertext {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  padding-left: 100px;
}

.form {
  justify-content: center;
  margin-top: 1em;
}

.formDetails {
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px, rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px;
  background: white;
  padding: 2em;
}

.userDetails {
  font-size: 14px;
  font-weight: normal;
  overflow-wrap: break-word;
}

.checkbox-125 {
  height: 16px;
  width: 16px;
}

.text-127 {
  font-size: small;
  margin-top: -2px;
}

.consent {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  overflow-wrap: break-word;
}

.consentMessage {
  font-size: 14px;
  font-weight: normal;
  overflow-wrap: break-word;
  padding: 0px;
}


button {
  margin: 10px;
}

#loader {
  position: absolute;
  left: 49%;
  top: 50%
}

.consentButton {
  margin-left: -10px;
}

.successImg {
  height: 25px;
  width: 25px;
  padding-left: 10px;
  padding-top: 2px;
}

td {
  padding: 10px;
}